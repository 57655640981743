import React from "react";
import BackgroundImage from "gatsby-background-image";
import "./HeroComponent.style.scss";
import { Link } from "gatsby";

export default function HeroComponent({
  image,
  title,
  buttonObject = [],
  backgroundDark = true,
  highlighter = false,
}) {
  return (
    <BackgroundImage backgroundColor={`#0080FF`} fluid={image} className="hero">
      <div className="HeroComponent ">
        {highlighter && <Highlighter />}
        <div className={"HeroContainer"}>
          <h1>{title}</h1>
          {buttonObject.length > 0 && (
            <div className="heroButtons">
              {buttonObject.map((v, k) => {
                return (
                  <Link key={k} to={v.link}>
                    <div className={v.active && "active"}>{v.title}</div>
                  </Link>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </BackgroundImage>
  );
}

const Highlighter = () => {
  return (
    <div className="highlighter">
      <div className="pointer" style={{ top: "80%", left: "18%" }}>
        <div className="dialog">
          <b>Lizard Control</b>
          <br />
          Due to fear and aesthetic factors at home and in the food and
          pharmaceutical industries.
        </div>
      </div>
      <div className="pointer" style={{ top: "60%", left: "20%" }}>
        <div className="dialog">
          <b>Bud Bug</b>
          <br />
          An effective Bed Bug control starts with a thorough inspection by our
          Bed Bug experts.
        </div>
      </div>
      <div className="pointer" style={{ top: "75%", left: "10%" }}></div>
      <div className="pointer" style={{ top: "25%", left: "50%" }}>
        <div className="dialog">
          <b>Lizard Control</b>
          <br />
          Due to fear and aesthetic factors at home and in the food and
          pharmaceutical industries.
        </div>
      </div>
    </div>
  );
};
