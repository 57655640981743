import React from "react";
import Layout from '../../../components/Layout'
import { FaAngleRight } from "@react-icons/all-files/fa/FaAngleRight";
import { Link,graphql,navigate } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { useState, useEffect } from "react";
import Accordion from "../../../components/Accordion";
import SocialConnect from "../../../components/ConnectSocial";
import HeroComponent from "../../../components/hero/HeroComponent";

const ComponentName = ({ data }) => {
  
  const daa = data.strapiArticles
  return (
    <Layout title={data.strapiArticles.title}>
   
      <div className="about">
      <BackgroundImage
        backgroundColor={`#0080FF`}
        fluid={daa.image.localFile.childImageSharp.fluid}
         className="hero">
         <div className="overlay">
           <h1>{daa.hindiTitle}</h1>
          </div>
          </BackgroundImage>

        <div className="ssbody wrapper_max">
    
          <div className="content">
          {/* <div  className="hindiLink"  style={{cursor:'pointer'}} onClick={()=>{  navigate('/blog/'+daa.slug)}} data-nosnippet>Read in English</div> */}
            <div className='page-body' dangerouslySetInnerHTML={{__html: daa.hindiBody}} />
              <Accordion data={daa.hindiSection}/>
            <SocialConnect hindi={true} share={true}/>
          </div>
        </div>
      </div>


    </Layout>
  );
};

export const query = graphql`
  query articlesQueryAndArticlesQuery($slug: String!) {
    strapiArticles(slug: { eq: $slug }) {
      id
      slug
      hindiTitle
      hindiBody
      hindiSection{
        body
        title
      }
      image {
        localFile {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1900) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;

export default ComponentName;
